import { Controller } from "@hotwired/stimulus"

let easyAutocompleteModule = undefined;

export default class extends Controller {
  static targets = ["priceListItemId", "productId", "className", "name", "description", "unitAmount", "tax"]

  initialize() {
    easyAutocompleteModule ||= import('../app/components/jquery.easy-autocomplete');
    this.url = this.data.get('url')
  }

  connect() {
    var $element = $(this.nameTarget)

    var self = this

    let options = {
      maxNumberOfElements: 10,
      adjustWidth: false,

      url: function(query) {
        return `${self.url}?q=${query}`
      },

      getValue: function(element) {
				return element.name.replace(/</g, "&lt;").replace(/>/g, "&gt;"); //
      },

      requestDelay: 500,

      minCharNumber: -1,

      list: {
        // pause autosubmit while autocompleting data
        onLoadEvent: function() {
          $(document).trigger('autosubmit:pause') // pause autosubmit
        },
        onHideListEvent: function() {
          $(document).trigger('autosubmit:continue') // enable autosubmit back
        },

        onChooseEvent: function() {
			    let itemData = $element.getSelectedItemData()
          self.descriptionTarget.value = itemData.description

          if(self.hasUnitAmountTarget) {
            self.unitAmountTarget.value = itemData.price_amount
            self.unitAmountTarget.dispatchEvent(new Event('change')) // recalculate amounts
          }

          if(itemData.class_name == "Product") {
            self.productIdTarget.value = itemData.id
            $.post(`/products/${itemData.id}/make_recent`, {_method: 'patch'})
          } else {
            self.priceListItemIdTarget.value = itemData.id
            $.post(`/price_list_items/${itemData.id}/make_recent`, {_method: 'patch'})
          }


          if(!itemData.taxable) {
            self.taxTarget["tax-select"].chooseNonTaxable()
          }

          // enable autosubmit and trigger change event to make save happen
          $(document).trigger('autosubmit:continue')
          $element.trigger('change')
		    }
	    }
    }

    easyAutocompleteModule.then(() => $element.easyAutocomplete(options))
  }
}
