import { Controller } from "@hotwired/stimulus"

// Check a parent 'Check All' checkbox will check/uncheck all children checkboxes
// if not all children are checked and unchecked shows indeterminate state
export default class extends Controller {
  static targets = ['parent', 'child', 'checkedCount']

  connect() {
    this.updateCheckAllState();
  }

  checkAll(event) {
    const checked = event.target.checked
    this.childTargets.forEach( child => child.checked = checked)
    this.updateCount();
  }

  checkChild(event) {
    this.updateCheckAllState();
    this.updateCount();
  }

  updateCheckAllState(){
    const isSomeChecked = this.childTargets.some(child => child.checked)
    const isSomeUnchecked = this.childTargets.some(child => !child.checked)
    this.parentTarget.checked = isSomeChecked && !isSomeUnchecked
    this.parentTarget.indeterminate = isSomeChecked && isSomeUnchecked
  }

  updateCount() {
    if(!this.checkedCountTarget) return
    this.checkedCountTarget.textContent = this.childTargets.filter(c => c.checked).length || ''
  }
}
