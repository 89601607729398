import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

var stripeScriptLoaded = false;

export default class extends Controller {
  static values = {
    currency: { type: String, default: 'usd' },
    accountId: String
  }

  static targets = ["cardElement", "addressElement",
                    "amount",
                    "cardErrors", "loadErrors"]

  initialize() {
    if(stripeScriptLoaded) {
      this.init()
    } else {
      this._loadStripeScript()
    }
  }

  init() {
    try {
      var self = this

      // handle submit via flag to keep original handler (ajax/regular)
      this.readyToSubmit = false

      this.$form = $(this.element)

      this.stripe = Stripe(this.data.get('key'))

      let options = {
        mode: 'payment',
        amount: this.__getPaymentAmount(),
        currency: this.currencyValue.toLowerCase(),
        paymentMethodTypes: ['card'],
        paymentMethodCreation: 'manual',
        onBehalfOf: this.accountIdValue,
        // Customizable with appearance API: https://docs.stripe.com/elements/appearance-api
        appearance: {
          theme: 'stripe',
          variables: {
            fontSizeBase: '13px',
            colorText: '#495057',
            colorTextPlaceholder: '#969696',
            border: '1px solid rgba(0, 40, 100, 0.12)',
            borderRadius: '3px',
          },
          rules: {
            '.Label': {
              fontSize: '0px' // hack to hide labels, "display" property is not supported by Stripe
            },
            '.Input:focus': {
              borderColor: '#3796F7',
              boxShadow: 'inset 0 1px 1px rgba(#000, .075)',
              outline: '0',
            },
            '.Input--invalid': {
              boxShadow: 'inset 0 1px 1px rgba(#000, .075)',
            }
          }
        }
      }

      this.stripeElements = this.stripe.elements(options)

      this.card = this.stripeElements.create('payment')
      this.card.mount(this.cardElementTarget)

      if(this.hasAddressElementTarget) {
        let addressOptions = { mode: 'billing' }
        this.address = this.stripeElements.create('address', addressOptions)
        this.address.mount(this.addressElementTarget)
      }

      this.$form.submit(function(event) {
        if(!self.readyToSubmit && $(self.cardElementTarget).is(':visible')) {
          self.stripeElements.submit().then(function(result) { // elements validation before requesting confirmation token
            if(result.error) {
              self.cardErrorsTarget.innerText = result.error.message
              self.readyToSubmit = false
            } else {
              self.stripe.createConfirmationToken({elements: self.stripeElements}).then(function(result) {
                if(result.error) {
                  // Inform the user if there was an error.
                  self.cardErrorsTarget.innerText = result.error.message
                  self.readyToSubmit = false
                } else {
                  // Send the token to your server.
                  self.readyToSubmit = true
                  self.__stripeTokenHandler(result.confirmationToken)
                }
              })
            }
          })
        } else {
          // submit form as usual if card control is hidden
          self.readyToSubmit = true
        }

        if(!self.readyToSubmit) {
          return false
        }
      })

    } catch(e) {
      // TODO: remove
      console.log(e)
      this.__loadError()
    }
  }

  __getPaymentAmount() {
    let amountValue = this.amountTarget.value.replaceAll(',', '')
    let amount = parseFloat(amountValue)
    if( isNaN(amount) ) amount = 0
    return Math.trunc(amount * 100)
  }

  updateAmount() {
    let amount = this.__getPaymentAmount()
    if(amount > 0) this.stripeElements.update({amount: amount})
  }

  // Submit the form with the token ID.
  __stripeTokenHandler(token) {
    let hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'stripe_token')
    hiddenInput.setAttribute('value', token.id)

    this.$form.append(hiddenInput)

    // if the form "remote" submit it with the Rails default handler
    // otherwise use regular submit method
    if(this.$form.data("remote")) {
      Rails.fire(this.$form.get(0), 'submit')
    } else {
      this.$form.get(0).submit()
    }
  }

  _loadStripeScript(){
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => this.init();
    script.src = "https://js.stripe.com/v3/";
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  __loadError() {
    $(this.loadErrorsTarget).removeClass('d-none')
    this.readyToSubmit = false
    this.$form.submit(() => false)
  }

  // confirmationToken can be used only once, so if there is server error happen token need to be generated again
  // reset the current one and a form state by triggering this action with window@formInvalid event
  resetStateOnError(event) {
    this.readyToSubmit = false
    let stripeTokenElement = document.getElementsByName("stripe_token")[0]
    if( stripeTokenElement ) {
      stripeTokenElement.parentNode.removeChild(stripeTokenElement)
    }
  }
}
