import { Controller } from "@hotwired/stimulus";

// controller closes modal menu when item is clicked and next modal is opened
export default class extends Controller {

  connect() {
    this.modal = this.element.querySelector('#actions-menu-modal')
    this.element.querySelectorAll('.actions-menu-item').forEach(item =>
      item.addEventListener("click", item.boundHide = this.hide.bind(this))
    )
  }

  disconnect() {
    this.element.querySelectorAll('.actions-menu-item').forEach(item =>
      item.removeEventListener("click", item.boundHide)
    )
  }

  hide() {
    $(this.modal).modal('hide')
  }
}