import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

var stripeScriptLoaded = false;

export default class extends Controller {
  static targets = ["cardElement",
                    "cardNumberElement", "cardExpiryElement", "cardCvcElement", "cardZipElement",
                    "cardErrors", "loadErrors"]

  initialize() {
    if(stripeScriptLoaded) {
      this.init()
    } else {
      this._loadStripeScript()
    }
  }

  init() {
    try {
      var self = this

      // handle submit via flag to keep original handler (ajax/regular)
      this.readyToSubmit = false

      this.$form = $(this.element)

      this.stripe = Stripe(this.data.get('key'))
      this.stripeElements = this.stripe.elements()

      if(this.hasCardElementTarget) {
        this.__initSingleInputWidget()
      } else {
        this.__initMultInputsWidget()
      }

      this.$form.submit(function(event) {
        if(!self.readyToSubmit && $(self.stripeElementTarget).is(':visible')) {
          self.stripe.createToken(self.stripeElement, self.__stripeSubmitData()).then(function(result) {
            if(result.error) {
              // Inform the user if there was an error.
              self.cardErrorsTarget.innerText = result.error.message
              self.readyToSubmit = false
            } else {
              // Send the token to your server.
              self.readyToSubmit = true
              self.__stripeTokenHandler(result.token)
            }
          })
        } else {
          // submit form as usual if card control is hidden
          self.readyToSubmit = true
        }

        if(!self.readyToSubmit) {
          return false
        }
      })

    } catch(e) {
      this.__loadError()
    }
  }

  __initSingleInputWidget() {
    this.card = this.stripeElements.create('card', {
      classes: {
        base: 'StripeCardElement'
      },
      style: {
        base: {
          fontSize: '13px',
          '::placeholder': {
            color: '#969696'
          }
        }
      }
    })

    this.card.mount(this.cardElementTarget)

    this.card.addEventListener('change', this.__errorsHandler.bind(this))

    this.stripeElementTarget = this.cardElementTarget
    this.stripeElement = this.card
  }

  __initMultInputsWidget() {
    this.cardNumber = this.stripeElements.create('cardNumber')
    this.cardExpiry = this.stripeElements.create('cardExpiry')
    this.cardCvc = this.stripeElements.create('cardCvc')
    this.cardZip = $(this.cardZipElementTarget)

    this.cardNumber.mount(this.cardNumberElementTarget)
    this.cardExpiry.mount(this.cardExpiryElementTarget)
    this.cardCvc.mount(this.cardCvcElementTarget)

    this.cardNumber.addEventListener('change', this.__errorsHandler.bind(this))
    this.cardExpiry.addEventListener('change', this.__errorsHandler.bind(this))
    this.cardCvc.addEventListener('change', this.__errorsHandler.bind(this))

    // handle stripe classes on ZIP input manually, because Stripe does not provide control for it
    this.cardZip.on('focus', () => this.cardZip.addClass('StripeElement--focus'))
    this.cardZip.on('blur', () => this.cardZip.removeClass('StripeElement--focus'))
    this.cardZip.on('keyup', () => {
      if(this.cardZip.val().length === 0) {
        this.cardZip.addClass('StripeElement--empty');
      } else {
        this.cardZip.removeClass('StripeElement--empty');
      }
    })
    this.cardZip.on('change', () => {
      let element = this.cardZip[0]
      if(element.checkValidity) {
        if(element.checkValidity()) {
          this.cardZip.removeClass('StripeElement--invalid');
        } else {
          this.cardZip.addClass('StripeElement--invalid');
        }
      }
    })

    this.stripeElementTarget = this.cardNumberElementTarget
    this.stripeElement = this.cardNumber
  }

  // Handle real-time validation errors from the stripe elements
  __errorsHandler(event) {
    if(event.error) {
      this.cardErrorsTarget.innerText = event.error.message
    } else {
      this.cardErrorsTarget.innerText = ''
    }
  }

  __stripeSubmitData() {
    let data = {}
    if(!this.hasCardElementTarget) {
      data = {
        address_zip: this.cardZip.val()
      }
    }

    return data
  }

  // Submit the form with the token ID.
  __stripeTokenHandler(token) {
    let hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'stripe_token')
    hiddenInput.setAttribute('value', token.id)

    this.$form.append(hiddenInput)

    // if the form "remote" submit it with the Rails default handler
    // otherwise use regular submit method
    if(this.$form.data("remote")) {
      Rails.fire(this.$form.get(0), 'submit')
    } else {
      this.$form.get(0).submit()
    }
  }

  _loadStripeScript(){
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => this.init();
    script.src = "https://js.stripe.com/v3/";
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  __loadError() {
    $(this.loadErrorsTarget).removeClass('d-none')
    this.readyToSubmit = false
    this.$form.submit(() => false)
  }
}
