import moment from 'moment'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["durationYears",
                    "totalAmount",
                    "weeklyAmount", "biweeklyAmount",
                    "monthlyAmount", "sixMonthsAmount",
                    "quartelyAmount", "annualAmount", "oneTimeAmount"]

  updateYears() {
    $("label[for='subscription_plan_total']").html(`Total price for ${this.durationYearsTarget.value} years`)
  }

  updateAmounts(event) {
    let totalAmount = 0
    let weeklyAmount = 0
    let biweeklyAmount = 0
    let monthlyAmount = 0
    let sixMonthsAmount = 0
    let quartelyAmount = 0
    let annualAmount = 0
    let oneTimeAmount = 0
    let durationYears = parseInt(this.durationYearsTarget.value)
    let weeksCount = moment().isoWeeksInYear()
    let biweeksCount = weeksCount / 2


    switch (event.target.id) {
      case 'subscription_plan_total':
        totalAmount = parseFloat(event.target.value)
        annualAmount = totalAmount / durationYears
        break
      case 'subscription_plan_weekly_amount':
        weeklyAmount = parseFloat(event.target.value)
        annualAmount = weeklyAmount * weeksCount
        break
      case 'subscription_plan_biweekly_amount':
        biweeklyAmount = parseFloat(event.target.value)
        annualAmount = biweeklyAmount * biweeksCount
      case 'subscription_plan_monthly_amount':
        monthlyAmount = parseFloat(event.target.value)
        annualAmount = monthlyAmount * 12
        break
      case 'subscription_plan_six_months_amount':
        sixMonthsAmount = parseFloat(event.target.value)
        annualAmount = sixMonthsAmount * 2
        break
      case 'subscription_plan_quartely_amount':
        quartelyAmount = parseFloat(event.target.value)
        annualAmount = quartelyAmount * 4
      case 'subscription_plan_annual_amount':
        annualAmount = parseFloat(event.target.value)
        break
      case 'subscription_plan_one_time_amount':
        oneTimeAmount = parseFloat(event.target.value)
        annualAmount = oneTimeAmount / durationYears
        break
    }

    if( isFinite(durationYears) ) { // durationYears is set, so total and one-time can be set
      totalAmount = annualAmount * durationYears
      oneTimeAmount = totalAmount
    }

    weeklyAmount = annualAmount / weeksCount
    biweeklyAmount = annualAmount / biweeksCount
    monthlyAmount = annualAmount / 12
    sixMonthsAmount = annualAmount / 2
    quartelyAmount = annualAmount / 4

    this.totalAmountTarget.value = totalAmount.toFixed(2)
    this.weeklyAmountTarget.value = weeklyAmount.toFixed(2)
    this.biweeklyAmountTarget.value = biweeklyAmount.toFixed(2)
    this.monthlyAmountTarget.value = monthlyAmount.toFixed(2)
    this.sixMonthsAmountTarget.value = sixMonthsAmount.toFixed(2)
    this.quartelyAmountTarget.value = quartelyAmount.toFixed(2)
    this.annualAmountTarget.value = annualAmount.toFixed(2)
    this.oneTimeAmountTarget.value = oneTimeAmount.toFixed(2)
  }
}
