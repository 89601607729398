import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["serviceSelector", "list"];

  connect() {
    Sortable.create(this.listTarget, {});
  }

  disconnect() {
    Sortable.get(this.listTarget).destroy()
  }

  onChangeServiceSelector(e) {
    if ($(this.listTarget).find("#item-" + e.detail.value)[0]) {
      Notify.error("This service is already added");
    } else {
      $(this.listTarget).append(e.detail.content);
    }
  }

  onDelete(e) {
    $(e.currentTarget).closest("tr").remove();
  }
}
